/**
 * 動画を遅延読み込みするクラス
 * @param {*} _parm.video <video>タグ
 * @param {*} _parm.src.{pc, sp} 動画のsrc pcは必須 spで出し分けるならspも設定する（絶対パス、相対パスどちらでも可）
 * @param {*} _parm.breackPoint _index.jsで定義しているグローバルなbreackPointを与える
 */

export default class VideoLazyLoad {
  constructor(_parm) {
    this.elmVideo = document.querySelector(_parm.video)
    this.src = {
      pc: _parm.src.pc || false,
      sp: _parm.src.sp || false
    }
    this.breackPoint = _parm.breackPoint
  }

  /**
   * sourceタグを生成する
   * @param {String} _src 動画ソース（PC, SPで出し分けられるように引数にしている）
   */
  createElmSource(_src) {
    let elmSource = document.createElement('source')
    elmSource.setAttribute('type', 'video/mp4')
    elmSource.setAttribute('src', _src)
    this.elmVideo.appendChild(elmSource)
  }

  init() {
    if (window.innerWidth > this.breackPoint.tb) {
      this.createElmSource(this.src.pc);
      return;
    }
    if (!this.src.sp) {
      this.createElmSource(this.src.pc);
    } else{
      this.createElmSource(this.src.sp);
    }
  }
}
