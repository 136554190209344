/**
 * 記事中のバナーを一括で差し替える（画像とリンク先）
 */

export default class BannerReplace {
  constructor(_parm) {
    // 既存バナーの画像
    this.beforeBanner = 'https://kinoaru.com/wp-content/uploads/2021/10/bnr_tp_lp.webp';
    // 差し替え語のバナーの画像
    this.afterBanner = 'https://kinoaru.com/wp-content/uploads/2022/10/bnr_bb1.png';
    // 差し替え語のバナーのURL
    this.afterHref = 'https://www.fin-bigbox.com/?utm_source=kinoaru&utm_medium=banner';
  }

  /**
   * 対象のバナーのa要素を取得（複数）
   * @returns Array
   */
  getTargetBanner() {
    return [...document.querySelectorAll('a[href^="https://mh.yoshimei.co.jp/3m/?utm_source=kinoaru&utm_medium=banner"]')];
  }

  /**
   * バナーを差し替える
   * @param {Element} _elmBanner バナー要素（aタグ）
   */
  replaceBanner(_elmBanner) {
    // 記事情報
    const postId = document.querySelector('#js-post-id').value;
    // 新しいバナーの情報を生成
    const newHref = `${this.afterHref}&utm_campaign=${postId}`;
    const newSrc = this.afterBanner;
    // 要素書き換え
    _elmBanner.href = newHref;
    while(_elmBanner.firstChild) {
      _elmBanner.removeChild(_elmBanner.firstChild);
    }
    _elmBanner.insertAdjacentHTML('beforeend', `<img src="${newSrc}" alt="">`);
  }

  init() {
    const arrayElmsBanner = this.getTargetBanner();
    if(!arrayElmsBanner.length) return;
    arrayElmsBanner.forEach(_elmBanner => {
      this.replaceBanner(_elmBanner);
    });
  }
}
