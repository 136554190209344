/**
 * 記事ページの目次を生成するクラス
 */

export default class ArticleOutline {
  constructor(_parm) {
    this.elmOutlineWrap = document.querySelector(_parm.outline)
    this.elmHeadings = [...document.querySelectorAll('#js-wp-the-content h2')]
    this.currentClass = '__current'
  }

  /**
   * 目次生成に必要な見出しのデータを作成する
   * @returns Object
   */
  getOutlineData() {
    const outlineData = this.elmHeadings.map((_elmHeading, _index) => {
      return {
        id: _index,
        elm: _elmHeading,
        text: _elmHeading.textContent,
      }
    });
    return outlineData;
  }

  /**
   * 見出しにidをセットする
   * @param {Object} _outlineData this.getOutlineData() を引数に与える
   */
  setIdHeadingElm(_outlineData) {
    _outlineData.forEach(_data => {
      _data.elm.id = `sec-${_data.id}`
    });
  }

  /**
   * 目次リンクを生成する
   * @param {Object} _outlineData this.getOutlineData() を引数に与える
   */
  createOutlineLinks(_outlineData) {
    _outlineData.forEach((_data, _index) => {
      let createElmBaseLink = document.createElement('a')
      createElmBaseLink.textContent = _data.text
      createElmBaseLink.setAttribute('href', `#sec-${_data.id}`)
      if(_index === 0) {
        createElmBaseLink.classList.add(this.currentClass)
      }
      this.elmOutlineWrap.append(createElmBaseLink)
    });
  }

  /**
   * 目次リンクにクリックイベントを設定する
   * @param {Object} _elmOutlineLinks 目次リンクの [...document.querySelectorAll()]
   */
  addClickEventOutlineLinks(_elmOutlineLinks) {
    _elmOutlineLinks.forEach(_elmOutlineLink => {
      _elmOutlineLink.addEventListener('click', (_ev) => {
        [..._ev.target.parentNode.children].forEach(_link => {
          _link.classList.remove(this.currentClass);
        });
        _ev.target.classList.add(this.currentClass);
      })
    })
  }

  init() {
    const outlineData = this.getOutlineData();
    this.setIdHeadingElm(outlineData);
    this.createOutlineLinks(outlineData);
    // クリックイベントを設定しなくても、IntersectionObserver()でclassの付け外しが可能
    // this.addClickEventOutlineLinks([...this.elmOutlineWrap.children]);

    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio === 1) {
          const targetId = entry.target.id
          const elmOutlineLinks = [...this.elmOutlineWrap.children]
          elmOutlineLinks.forEach(_elmOutlineLink => {
            if(_elmOutlineLink.getAttribute('href') === `#${targetId}`){
              _elmOutlineLink.classList.add(this.currentClass)
            } else{
              _elmOutlineLink.classList.remove(this.currentClass)
            }
          });
        } else if (! entry.isIntersecting) {
          // console.log(entry.target)
        }
      })
    }
    const option = {
      // 交差の閾値 0=要素が少しでも重なったら, 100=要素が全部重ならないとコールバックが実行されない
      threshold: 1,
      rootMargin: '-1px 0px -75% 0px',
    }
    const observer = new IntersectionObserver(
      callback, option
    )
    outlineData.forEach(_data => {
      observer.observe(_data.elm)
    });

  }
}
