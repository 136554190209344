/**
 * モーリくんフォームページに関するクラス
 */

export default class MoriForm {
  constructor(_parm) {
    // 「土地の検討状況」のラジオボタン要素で、選択されたら表示/非表示が切り替わる対象要素のvalue値
    this.landKey = '興味があるものが家具やグッズで、土地は関係がない';
    // 「個人or法人」のラジオボタン要素で、選択されたら表示/非表示が切り替わる対象要素のvalue値
    this.userElementKey = '法人として';

    // 「土地の検討状況」のラジオボタン要素（複数系）
    this.elmLandRadioList = [...document.querySelectorAll('#js-radio-land [type="radio"]')];
    // 「個人or法人」のラジオボタン要素（複数系）
    this.elmUserElementRadioList = [...document.querySelectorAll('#js-radio-user-element [type="radio"]')];

    // 「土地の検討状況」の「興味があるものが家具やグッズで、土地は関係がない」が選択されたら非表示にする要素（複数系）
    this.elmOtherLandCheckedIsHideList = [...document.querySelectorAll('[data-otherland-checked-is-hide]')];
    // 「個人or法人」の「法人として」が選択されたら非表示にする要素（複数系）
    this.elmCorporationCheckedIsHideList = [...document.querySelectorAll('[data-corporation-checked-is-hide]')];
  }

  /**
   * フォームの項目の表示/非表示を切り替える
   * @param {Array} arrayElmsTarget 切り替え対象となる要素（配列）
   * @param {Boolean} isHide Trueなら非表示に、Falseなら表示する
   */
  toggleFormRow(arrayElmsTarget, isHide) {
    const displayValue = isHide ? 'none' : 'block';
    arrayElmsTarget.forEach(elmTarget => {
      elmTarget.style.display = displayValue;
    });
  }

  /**
   * イベント付与
   */
  addEvent() {
    this.elmLandRadioList.forEach(elmRadio => {
      elmRadio.addEventListener('input', (_ev) => {
        const isCheckedHideRow = _ev.currentTarget.value === this.landKey && _ev.currentTarget.checked;
        this.toggleFormRow(this.elmOtherLandCheckedIsHideList, isCheckedHideRow);
      });
    });
    this.elmUserElementRadioList.forEach(elmRadio => {
      elmRadio.addEventListener('input', (_ev) => {
        const isCheckedHideRow = _ev.currentTarget.value === this.userElementKey && _ev.currentTarget.checked;
        this.toggleFormRow(this.elmCorporationCheckedIsHideList, isCheckedHideRow);
      });
    });
  }

  /**
   * 初期化
   */
  init() {
    if(!this.elmLandRadioList.length) return;
    if(!this.elmUserElementRadioList.length) return;
    // ページ読み込み時にも、選択状態に応じて表示/非表示を切り替える
    this.toggleFormRow(this.elmCorporationCheckedIsHideList, this.elmLandRadioList.find((e) => e.value === this.landKey).checked);
    this.toggleFormRow(this.elmCorporationCheckedIsHideList, this.elmUserElementRadioList.find((e) => e.value === this.userElementKey).checked);
    this.addEvent();
  }
}
