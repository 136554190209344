/**
 * クリップボードに任意のテキストをコピーするクラス
 * @see https://qiita.com/simiraaaa/items/2e7478d72f365aa48356
 */

export default class CopyToClipboard {
  constructor(_parm) {
    this.elmCopyBtns = [...document.querySelectorAll(_parm.copyBtn)]
  }

  /**
   * 空要素を生成 & テキスト選択状態にしてクリップボードにコピーする
   * @param {String} string コピーするテキスト
   */
  execCopy(_copyText) {
    // コピー用要素を生成してコピーするテキストをセット
    let elmCopyTextWrap = document.createElement('div')
    let elmPre = document.createElement('pre')
    // user-select: none だとコピーできないから書き換え
    elmPre.style.userSelect = 'auto'
    elmCopyTextWrap.appendChild(elmPre).textContent = _copyText

    // 要素を画面外へ
    Object.assign(elmCopyTextWrap.style, {
      position: 'fixed',
      right: '200%',
    });

    // body に追加
    document.body.appendChild(elmCopyTextWrap);
    // 要素を選択
    document.getSelection().selectAllChildren(elmCopyTextWrap);

    // クリップボードにコピー
    const result = document.execCommand('copy');

    // 要素削除
    document.body.removeChild(elmCopyTextWrap);
    return result;
  }

  /**
   * アラート要素を生成
   */
  createAlert(_text) {
    let elmAlert = document.createElement('div')
    elmAlert.textContent = _text
    Object.assign(elmAlert.style, {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '300px',
      padding: '20px 20px',
      color: '#fff',
      fontSize: '12px',
      textAlign: 'center',
      background: 'rgba(0,0,0,.8)',
      opacity: '0',
      transition: 'opacity .4s',
    });
    // アラート表示 → 非表示 → 要素削除
    async function sleep(delay, result) {
      return new Promise(resolve => {
        setTimeout(() => resolve(result), delay);
      });
    }
    async function exec() {
      document.body.appendChild(elmAlert);
      await sleep(200)
      elmAlert.style.opacity = 1;
      await sleep(1500)
      elmAlert.style.opacity = 0;
      await sleep(1000)
      elmAlert.remove();
    }
    exec()
  }

  addEvent() {
    this.elmCopyBtns.forEach(_elmCopyBtn => {
      _elmCopyBtn.addEventListener('click', (_ev) => {
        if(this.execCopy(location.href)) {
          this.createAlert('URLをコピーしました。')
        } else {
          alert('URLのコピーに失敗しました。\nお使いのブラウザは対応していません。');
        }
      })
    });
  }

  init() {
    this.addEvent();
  }
}
