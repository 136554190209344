/**
 * 
 * トグルアニメーションを実装
 * 
 */

// jQuery
import $ from 'jquery';

// トグルアニメーション
export default function () {
  $('.c-faq-toggle__content').hide(); // 初期状態を非表示に
  // アコーディオンのクリックイベントを設定
  $('.c-faq-toggle__title').on('click', function () {
    // クリックされたアコーディオンのパネルを表示/非表示に切り替え
    $(this).next().stop().slideToggle();
    // アイコンの向きを変更
    $(this).toggleClass('is-open');
    return false;
  });
  
  // ハンバーガーメニューアニメーション
  $('.p-faq-header__sp-button').on('click', function () {
    $(this).toggleClass('is-active');
    $('.p-faq-header__fixed').toggleClass('is-active');
    $('.p-faq-header__sp-header').toggleClass('is-active');
  });
};
