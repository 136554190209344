import ArticleOutline from './module/ArticleOutline';
import CopyToClipboard from './module/CopyToClipboard';
import DrawerMenu from './module/DrawerMenu';
import SmoothScroll from './module/SmoothScroll';
import VideoLazyLoad from './module/VideoLazyLoad';
import BannerReplace from './module/BannerReplace';
import faqPage from './module/FaqPage';
import MoriForm from './module/MoriForm';

const init = () => {

  const breackPoint = {
    pc: 1096,
    tb: 860,
    sp: 640,
  };

  (() => {
    if (!document.querySelector('#js-header-drawer')) return;
    const headerDrawerMenu = new DrawerMenu({
      drawer: '#js-header-drawer',
      drawerOpenBtn: '#js-header-drawer-open-btn',
      drawerCloseBtn: '#js-header-drawer-close-btn',
      drawerBg: '#js-header-drawer-bg',
      attrToggle: 'data-active',
    });
    headerDrawerMenu.init();
  })();

  (() => {
    if (!document.querySelector('#js-article-outline')) return;
    const articleOutline = new ArticleOutline({
      outline: '#js-article-outline'
    });
    articleOutline.init();
  })();

  (() => {
    if (!document.querySelector('#mv-swiper')) return;
    const swiper = new Swiper('#mv-swiper', {
      loop: true,
      slidesPerView: 1.5, // 左右を見切れさせる
      centeredSlides: true, // 左右を見切れさせる
      spaceBetween: 24, // スライド間の余白
      speed: 500,
      breakpoints: {
        // 画面幅が[数値]以上の場合...（CSSのmin-screen的な）
        [breackPoint.tb]: {
          slidesPerView: 1.5
        },
        [breackPoint.sp]: {
          slidesPerView: 2
        },
      },
      autoplay: {
        delay: 4000,
      },
      pagination: {
        el: '.p-index-mv-swiper__pagination',
        clickable: true
      }
    });
  })();

  // articleOutline.init() より後に実行する
  (() => {
    const smoothScroll = new SmoothScroll({
      targets: 'a[href^="#"]',
      fixHeader: '#js-header',
    });
    smoothScroll.init();
  })();

  (() => {
    const copyToClipboard = new CopyToClipboard({
      copyBtn: '[data-js-copy-btn]',
    });
    copyToClipboard.init();
  })();

  (() => {
    if(!document.querySelector('#js-cta-footer-video-lazy')) return;
    const videoLazyLoadCtaFooter = new VideoLazyLoad({
      video: '#js-cta-footer-video-lazy',
      src: {
        pc: '/wp-content/themes/kinoaru/assets/img/footer/bg-movie.mp4',
        sp: false
      },
      breackPoint: breackPoint
    });
    videoLazyLoadCtaFooter.init();
  })();

  (() => {
    const bannerReplace = new BannerReplace();
    bannerReplace.init();
  })();

  (() => {
    faqPage();
  })();

  (() => {
    if(!document.querySelector('#js-mori-form-page')) return;
    const moriForm = new MoriForm();
    moriForm.init();
  })();

};

window.addEventListener('DOMContentLoaded', () => {
  init();
});
